<template>
  <el-main>
    <el-row style="margin-top: 50px">
      <el-col :span="6" :offset="9">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>修改密码</span>
          </div>
          <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="原密码" prop="old_password">
              <el-input show-password v-model="form.old_password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
              <el-input show-password v-model="form.new_password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="re_new_password">
              <el-input show-password v-model="form.re_new_password"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('form')">提交</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  name: "ChangePassword",
  data: function () {
    return {
      form: {
        old_password: '',
        new_password: '',
        re_new_password: ''
      },
      rules: {
        old_password: [
          {required: true, message: '请输入原密码', trigger: 'blur'},
        ],
        new_password: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
        ],
        re_new_password: [
          {required: true, message: '请输入确认密码', trigger: 'blur'},
        ]
      }
    }
  },
  methods: {
    onSubmit: function (name) {
      let me = this;
      me.$refs[name].validate(valid => {
        if (valid) {
          if (!this.form.new_password == this.form.re_new_password) {
            this.$message.error("两次密码输入不一致");
            return;
          }

          this.axios.post('/api/user/changePassword', me.form).then(() => {
            localStorage.clear();
            this.$router.push('/login');
          })
        }else {
          return false;
        }

      })

    }
  }
}
</script>

<style scoped>

</style>